import React from 'react'
import Dropdown from '../components/dropdown/Dropdown'
import BrowserTitleBar from '../components/titilebar/BrowserTitleBar'

function GameTiming() {
  return (
    <>
    <BrowserTitleBar title={"Game-timing"}/>
    <div className='p-3'>
        <Dropdown/>
        <div className='w-full my-5 bg-gray-100 shadow-md p-5 flex flex-col'>
                <div className='w-full border-b-2 border-purple-400 flex justify-between items-start'>
                    <p className="text-purple-400">KOLKATA FATAFAT</p>
                    <span className="text-purple-400 ">KF1</span>
                </div>

                <div className='w-full gap-1 my-2'>
                <p className='text-purple-400'>
                <i class="fa-regular fa-clock p-2"></i>Start Time:- 07:00</p>
                    <br />
                    <p className='text-purple-400'>
                    <i class="fa-regular fa-clock p-2"></i>End Time:- 10:00</p>
                    <br />
                    <p className='text-purple-400'>
                    <i class="fa-regular fa-clock p-2"></i>Result Time:- 10:30</p>
                </div>
            </div>
            <div className='w-full my-5 bg-gray-100 shadow-md p-5 flex flex-col'>
                <div className='w-full border-b-2 border-purple-400 flex justify-between items-start'>
                    <p className="text-purple-400">KOLKATA FATAFAT</p>
                    <span className="text-purple-400 ">KF2</span>
                </div>

                <div className='w-full gap-1 my-2'>
                <p className='text-purple-400'>
                <i class="fa-regular fa-clock p-2"></i>Start Time:- 10:01</p>
                    <br />
                    <p className='text-purple-400'>
                    <i class="fa-regular fa-clock p-2"></i>End Time:- 11:30</p>
                    <br />
                    <p className='text-purple-400'>
                    <i class="fa-regular fa-clock p-2"></i>Result Time:- 12:00</p>
                </div>
            </div>
            <div className='w-full my-5 bg-gray-100 shadow-md p-5 flex flex-col'>
                <div className='w-full border-b-2 border-purple-400 flex justify-between items-start'>
                    <p className="text-purple-400">KOLKATA FATAFAT</p>
                    <span className="text-purple-400 ">KF3</span>
                </div>

                <div className='w-full gap-1 my-2'>
                <p className='text-purple-400'>
                <i class="fa-regular fa-clock p-2"></i>Start Time:- 11:31</p>
                    <br />
                    <p className='text-purple-400'>
                    <i class="fa-regular fa-clock p-2"></i>End Time:- 13:00</p>
                    <br />
                    <p className='text-purple-400'>
                    <i class="fa-regular fa-clock p-2"></i>Result Time:- 13:30</p>
                </div>
            </div>

    </div>
    </>
  )
}

export default GameTiming