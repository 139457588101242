import React, { useState } from 'react'
import AmountInput from '../inputs/AddAmount';
// import Table from '../tables/Table';

function JorBijor() {

  const [error, setError] = useState('');
  const [number, setNumber] = useState('');
  const [amount, setAmount] = useState('');
  const [res, setRes] = useState([]);

  const [useDigits, setDigits] = useState([]);


  const [addEven, setAddEven] = useState(false);
  const [addOdd, setAddOdd] = useState(true);

  const handleAddEvenChange = () => {
    setAddEven(true);
    setAddOdd(false);
  };

  const handleAddOddChange = () => {
    setAddEven(false);
    setAddOdd(true);
  };

  const add = () => {
    if (useDigits.length > 0 && useDigits.includes(number)) {
        setError('Digit Already Used!');
        return;
    }
    if (!amount || !number) {
        setError('Amount and Number both are needed!');
        return;
    }
    if (amount && number) {
        // setRes(p => [...p, {
        //     id: Math.floor(Date.now() / 1000),
        //     digit: number,
        //     amount: amount
        // }])
        // setAmount('');
        // setNumber('');
        // setError('');
        // setDigits(p => [...p, number]);
    }
}

  return (

    <>
      <div className='w-full p-3'>
        <div className="mb-[0.125rem] block min-h-[1.5rem] ps-[1.5rem]">
          <input
            className="relative float-left -ms-[1.5rem] me-[6px] mt-[0.15rem] h-[1.125rem] w-[1.125rem] appearance-none rounded-[0.25rem] border-[0.125rem] border-solid border-purple-400 outline-none before:pointer-events-none before:absolute before:h-[0.875rem] before:w-[0.875rem] before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-checkbox before:shadow-transparent before:content-[''] checked:border-primary checked:bg-primary checked:before:opacity-[0.16] "
            type="checkbox"
            defaultValue=""
            id="checkboxEven"
            checked={addEven}
            onChange={handleAddEvenChange}
          />
          <label
            className="inline-block ps-[0.15rem] hover:cursor-pointer"
            htmlFor="checkboxEven"
          >
            Add Even Numbers
          </label>
        </div>
        {/*Default checked checkbox*/}
        <div className="mb-[0.125rem] block min-h-[1.5rem] ps-[1.5rem]">
          <input
            className="relative float-left -ms-[1.5rem] me-[6px] mt-[0.15rem] h-[1.125rem] w-[1.125rem] appearance-none rounded-[0.25rem] border-[0.125rem] border-solid border-purple-400 outline-none before:pointer-events-none before:absolute before:h-[0.875rem] before:w-[0.875rem] before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-checkbox before:shadow-transparent before:content-[''] checked:border-primary checked:bg-primary checked:before:opacity-[0.16]"
            type="checkbox"
            defaultValue=""
            id="checkboxOdd"
            defaultChecked=""
            checked={addOdd}
            onChange={handleAddOddChange}
          />
          <label
            className="inline-block ps-[0.15rem] hover:cursor-pointer"
            htmlFor="checkboxOdd"
          >
            Add Odd Numbers
          </label>
        </div>

        <AmountInput setAmount={setAmount} amount={amount} add={add} />
        <p className="text-center text-md text-red-600">{error && error}</p>
      </div>
    </>


  )
}

export default JorBijor