import { useEffect, useState } from 'react'
import { getCookie } from '../utils/cookies';

function useAuth() {
    const[isLogin,setLogin]=useState(false);
    
    useEffect(()=>{
        if(getCookie('auth_token')){
            setLogin(true);
        }
        else{
            setLogin(false);
        }
    },[])

  return [isLogin,setLogin]
}

export default useAuth