import React from 'react'
import Dropdown from '../components/dropdown/Dropdown'
import Table from '../components/tables/Table'
import BrowserTitleBar from '../components/titilebar/BrowserTitleBar'

function BidHistory() {
    return (
        <>
        <BrowserTitleBar title={"Bid-history"}/>
            <div className='p-3'>
                <Dropdown />

                <div class="relative overflow-x-auto p-5">
                    <Table
                        head={`
                     <tr>
                         <th scope="col" class="px-6 py-3">
                             Name
                         </th>
                                 <th scope="col" class="px-6 py-3">
                                     Bet
                                 </th>
                                 <th scope="col" class="px-6 py-3">
                                     Amount
                                 </th>
                                 <th scope="col" class="px-6 py-3">
                                     Date
                                 </th>
                                 <th scope="col" class="px-6 py-3">
                                     Status
                                 </th>
                            </tr>`}

                        body={
                            `<tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
            <th scope="row" class="px-6 py-4">
                Kolkata Fatafat
            </th>
            <td class="px-6 py-4">
                Single
            </td>
            <td class="px-6 py-4">
                500/-
            </td>
            <td class="px-6 py-4">
                20/3/24
            </td>
            <td class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                Success
            </td>
        </tr>
                <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
            <th scope="row" class="px-6 py-4">
                Kolkata Fatafat
            </th>
            <td class="px-6 py-4">
                Jori
            </td>
            <td class="px-6 py-4">
                2500/-
            </td>
            <td class="px-6 py-4">
                18/3/24
            </td>
            <td class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                Success
            </td>
        </tr>
        <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
            <th scope="row" class="px-6 py-4">
                Kolkata Fatafat
            </th>
            <td class="px-6 py-4">
                Single
            </td>
            <td class="px-6 py-4">
                500/-
            </td>
            <td class="px-6 py-4">
                20/3/24
            </td>
            <td class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                Success
            </td>
        </tr>`}
                    />
                </div>

            </div>
        </>
    )
}

export default BidHistory

