import CryptoJS from 'crypto-js';

const key='utsav';

export const encrypt=(data)=>{
    return CryptoJS.AES.encrypt(data.toString(), key).toString();
}

export const decrypt=(data)=>{
    const bytes = CryptoJS.AES.decrypt(data, key);
    return bytes.toString(CryptoJS.enc.Utf8);
}