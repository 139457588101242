import React from 'react'

function Dropdown() {
    const Fatafat = ["Kolkata Fatafat", "Mumbai Fatafat", "India Fatafat",
        "Bengal Fatafat", "Morning Fatafat", "Day Fatafat", "Evening Fatafat", "Night Fatafat"]
    return (
        <>
            <div className="max-w-sm mx-auto">
                <select
                    id="countries"
                    className="bg-gray-50 border border-purple-400 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                    {Fatafat.map((option, index) => (
                        <option key={index} value={option}>
                            {option}
                        </option>
                    ))}
                </select>
            </div>
        </>
    )
}

export default Dropdown