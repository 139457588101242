import React from 'react'
import { useState } from 'react';
import logo from '../images/logo2.png';
import BrowserTitleBar from '../components/titilebar/BrowserTitleBar';

const RefferNav = () => {
    const [nav, setNav] = useState("reffer and earn")

    return (
        <>
        <BrowserTitleBar title={"Reffer"}/>
            <ul className="flex border-b mt-4 justify-around">
                <li className="">
                    <a
                        className={"bg-white cursor-pointer inline-block border-l border-t border-r rounded-t py-2 px-4  font-semibold ".concat(nav === "reffer and earn" ? 'text-purple-700' : 'text-gray-400', '  ')}
                        onClick={() => setNav("reffer and earn")}
                        style={{ borderBottom: nav === "reffer and earn" ? "2px solid #ca5cdd" : "none" }}
                    >
                        Reffer and Earn
                    </a>
                </li>
                <li className="">
                    <a
                        className={"bg-white cursor-pointer inline-block py-2 px-4 border-l border-t border-r  hover:text-purple-800 font-semibold ".concat(nav === "refferal history" ? 'text-purple-700' : 'text-gray-400', '  ')}
                        onClick={() => setNav("refferal history")}
                        style={{ borderBottom: nav === "refferal history" ? "2px solid #ca5cdd" : "none" }}
                    >
                        Refferal History
                    </a>
                </li>
            </ul>
            <RefferNavSection nav={nav} />

        </>
    )

}
const RefferNavSection = ({ nav }) => {
    return (
        <div className="flex flex-col justify-center items-center shadow-[0_2px_10px_-3px_rgba(6,81,237,0.3)] p-6">
            {
                nav === "reffer and earn" ? <RefferandEarn /> : nav === "refferal history" ? <Refferhistory /> : <></>
            }
        </div>
    )
}
const RefferandEarn = () => {
    return (
        <>
            <div className="justify-center items-center flex flex-col h-[80vh] bg-white">
                <div className="w-full max-w-xs m-auto rounded p-5">
                    <header className="flex flex-col justify-center items-center ">
                        <img
                            className="w-20 mx-auto mb-5"
                            src={logo}
                        />
                        <br />
                        <div class="shadow-lg bg-white p-4 rounded border text-black">
                            <p class="text-purple-600 text-2xl">Your Referral Code: D973Y6</p>
                        </div>
                        <br />
                        <p className="text-purple-600">If a person registers with your referral code and plays the game,you will get <u class="underline">Rs 45.</u></p>
                        <br />
                    </header>
                    <div>
                        <button
                            className="w-full bg-purple-600 hover:bg-purple-600 text-white font-bold py-1 px-2 rounded"
                            type="submit"
                        >
                            <i className="fa-solid fa-share-nodes mr-1"></i> Share
                        </button>
                    </div>
                </div>
            </div>

        </>
    )
}
const Refferhistory = () => {
    return (
        <>
            <p>Reffer History</p>
        </>
    )
}

function Reffer() {
    return (
        <>
            <div>
                <RefferNav />
            </div>
        </>

    )
}

export default Reffer