import React, { useContext, useEffect, useState } from 'react'
import {
  Collapse,
  initTWE,
} from "tw-elements";
import Sidebar from './Sidebar';
import logo from '../../images/logo2.png';
import { useLocation, useNavigate } from 'react-router-dom';
import hamburger from '../../images/menuicon.png';
import backbutton from '../../images/previous.png';
import { AuthContext } from '../../context/authContext';
import { deleteCookie } from '../../utils/cookies';

function UpperNavbar() {
  const navigate = useNavigate();
  const location = useLocation();
  const authContext=useContext(AuthContext);

  const [sidebar, setSidebar] = useState(false)
  const [isHome, setHome] = useState(false);

  useEffect(() => {
    initTWE({ Collapse });
  }, [])

  useEffect(() => {
    if (location.pathname === '/') {
      setHome(true);
    }
    else {
      setHome(false);
    }
    setSidebar(false);
  }, [location.pathname])

  return (

    <>
      {
        sidebar ? <Sidebar setSideNav={setSidebar} /> : null
      }
      <nav className="relative bg-[#f1eef5] flex w-full flex-wrap items-center justify-between bg-zinc-50 py-2 shadow-dark-mild dark:bg-neutral-700 lg:py-4 border-b-2 border-gray-200">
        <div className="flex w-full flex-wrap items-center justify-between px-3">

          {/* left element */}
          {isHome ?
            <img src={logo} alt="" className='h-5 w-6 ' onClick={() => navigate("/")} />
            :
            <img src={backbutton} alt="" className='h-5 w-5 bg-transparent' onClick={() => window.history.back()} />
          }

          {/* middle element */}
          {/* <div className='flex gap-1 mr-10 justify-center items-center'>

            <i className="fa-solid fa-sack-dollar text-purple-500"></i>
            <p className='font-bold text-sm'>10,000</p>
          </div> */}

          {/* right element */}
          <div className='flex gap-4 items-center justify-between'>
            <div className='flex gap-3'>
              {authContext?.isLogin &&
                <button
                  type="button"
                  className="w-16 flex justify-center items-center h-8 rounded bg-red-500 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-primary-3 transition duration-150 ease-in-out hover:bg-primary-accent-300 hover:shadow-primary-2 focus:bg-primary-accent-300 focus:shadow-primary-2 focus:outline-none focus:ring-0 active:bg-primary-600 active:shadow-primary-2 dark:shadow-black/30 dark:hover:shadow-dark-strong dark:focus:shadow-dark-strong dark:active:shadow-dark-strong"
                  data-twe-ripple-init=""
                  data-twe-ripple-color="light"
                  onClick={()=>{
                    deleteCookie('auth_token');
                    authContext.setLogin(false);
                  }}
                >
                  Logout
                </button>
              }
            </div>

            <div className='h-full flex justify-center items-center'>
              <button onClick={() => setSidebar(true)}>
                {!sidebar && <img src={hamburger} className='h-7 w-8' alt="" />}
              </button>
            </div>
          </div>
        </div>

      </nav>



    </>
  )
}

export default UpperNavbar