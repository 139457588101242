import React, { useEffect } from 'react'
import {
  Card,
  Typography,
  List,
  ListItem,
  ListItemPrefix
} from "@material-tailwind/react";

import fatafat_sidebar from '../../images/logobackgroundnew1.png';
import { Link } from 'react-router-dom';

function Sidebar({ setSideNav }) {


  return (
    <Card className="h-[calc(100vh-2rem)] bg-[#f0ebf7] rounded-none overflow-x-hidden z-10 fixed  w-full max-w-[20rem] p-4 shadow-xl shadow-blue-gray-900/5">
      <img src={fatafat_sidebar} className='h-[180px] w-full absolute z-1 top-0 left-0' alt="" />
      <div className="mb-2 p-4">
        <Typography variant="h5" color="blue-gray" className='flex justify-between items-center'>
          <p></p>
          <i className="fa-solid fa-xmark cursor-pointer relative text-white z-10" onClick={() => setSideNav(false)}></i>
        </Typography>
      </div>
      <div className='my-10 relative z-10'>
        <List>

        {/* <Link to={'/profile'}>
          <ListItem>
            <ListItemPrefix>
              <i className="fa-solid fa-user h-5 w-5"></i>
            </ListItemPrefix>
            Account
          </ListItem>
          </Link> */}

          {/* <Link to={'/add-money'}>
            <ListItem>
              <ListItemPrefix>
                <i className="fa-solid fa-wallet h-5 w-5"></i>
              </ListItemPrefix>
              Add Money
            </ListItem>
          </Link> */}

          {/* <Link to={'/withdraw'}>
            <ListItem>
              <ListItemPrefix>
                <i className="fa-solid fa-money-bill-transfer h-5 w-5"></i>
              </ListItemPrefix>
              Withdrawal Money
            </ListItem>
          </Link> */}

          <Link to={'/bid-history'}>
          <ListItem>
            <ListItemPrefix>
              <i className="fa-solid fa-clock-rotate-left h-5 w-5"></i>
            </ListItemPrefix>
            Play/Bid History
          </ListItem>
          </Link>

          {/* <Link to={'/transection-history'}>
          <ListItem>
            <ListItemPrefix>
              <i className="fa-solid fa-landmark h-5 w-5"></i>
            </ListItemPrefix>
            Transaction History
          </ListItem>
          </Link> */}

          <Link to={'/game-rules'}>
          <ListItem>
            <ListItemPrefix>
              <i className="fa-solid fa-scale-balanced h-5 w-5"></i>
            </ListItemPrefix>
            Game Rules
          </ListItem>
          </Link>

          <Link to={'/game-timing'}>
          <ListItem>
            <ListItemPrefix>
              <i className="fa-solid fa-hourglass-start h-5 w-5"></i>
            </ListItemPrefix>
            Game Timing
          </ListItem>
          </Link>


          {/* <Link to={'/reffer-and-earn'}>
            <ListItem>
              <ListItemPrefix>
                <i className="fa-solid fa-users-gear h-5 w-5"></i>
              </ListItemPrefix>
              Refer and Earn
            </ListItem>
          </Link> */}

          <Link to={'/share'}>
            <ListItem>
              <ListItemPrefix>
                <i className="fa-solid fa-share-nodes h-5 w-5"></i>
              </ListItemPrefix>
              Share
            </ListItem>
          </Link>

          {/* <Link to={'/bank-details'}> 
          <ListItem>
            <ListItemPrefix>
              <i className="fa-solid fa-money-check h-5 w-5"></i>
            </ListItemPrefix>
            Our Bank Details
          </ListItem>
          </Link> */}


          {/* <Link to={'/feedback'}>
            <ListItem>
              <ListItemPrefix>
                <i className="fa-solid fa-comment-dots h-5 w-5"></i>
              </ListItemPrefix>
              Feedback
            </ListItem>
          </Link> */}

        </List>
      </div>
    </Card>
  )
}

export default Sidebar