import React from 'react'
import { useNavigate } from 'react-router-dom'
import { encrypt } from '../../utils/crypt';

function Card({data}) {
    const navigate=useNavigate();

    return (
        <>
            <div onClick={()=>navigate(`/game/${encrypt(data?.id).replace(/\//g, "")}`,{state:{data:data}})} class={"cursor-pointer w-36 h-28 rounded-md shadow-md shadow-blue-gray-900 flex justify-center items-center bg-gradient-to-r from-purple-700 via-purple-800 to-purple-900"}>
                <span className={'text-white text-xl font-semibold text-center p-3'}>{data?.name}</span>
            </div>

        </>
    )
}

export default Card