import React, { useEffect, useState } from 'react'
import PlayCards from '../components/playcards/PlayCards'
import { useNavigate, useParams } from 'react-router-dom'
import { useLocation } from 'react-router-dom';
import Loader from '../components/loader/Loader';



function PlaySectionArea() {
    const location = useLocation();
    const {urlid}=useParams();

    const navigate=useNavigate();

    const[gametime,setGametime]=useState([]);
    const[gametypes,setGameTypes]=useState([]);
    const[gameid,setGameid]=useState('');

    
    useEffect(()=>{
        try {
            const { id,game_data } = location.state;
            setGametime(game_data?.timing);
            setGameTypes(game_data?.game_types);
            setGameid(id);
        } catch (error) {
            navigate("/")
        }
    },[location.state])

  return (
    <>
        <div className='w-full flex flex-col p-4'>
            {
                gametime?.length>0?
                gametime?.map((v,i)=>
                    <PlayCards key={i} data={v} game_types={gametypes} id={gameid} urlid={urlid}/>
                )
                :<Loader/>
            }
        </div>
         
    </>
  )
}

export default PlaySectionArea