import { useLocation } from 'react-router-dom';
import background from '../images/background.png';
import Single from '../components/games/Single';
import Patti from '../components/games/Patti';
import CpPatti from '../components/games/CpPatti';
import JitOpposite from '../components/games/JitOpposite';
import JorBijor from '../components/games/JorBijor';
import TriplePatti from '../components/games/TriplePatti';
import Jori from '../components/games/Jori';
import BrowserTitleBar from '../components/titilebar/BrowserTitleBar';

function Game() {
    const location = useLocation();
    const { data } = location.state;
    let component;

    switch (data?.id) {
        case 1:
            component = <Single />
            break;
        case 2:
            component = <Patti/>
            break;
        case 3:
            component = <CpPatti/>
            break;
        case 4:
            component = <Jori/>
            break;    
        case 6:
            component = <TriplePatti/>
            break;            
        case 7:
            component = <JorBijor/>
            break;
        case 8:
            component = <JitOpposite/>
            break;        
            
        default:
    }

    return (
        <>
        <BrowserTitleBar title={"Games"}/>
            <div className='h-[80vh]'>
                <div className="relative h-[25vh]">
                    <img src={background} className='h-[25vh] w-full' alt="" />
                    <div className="absolute inset-0 flex items-center justify-center">
                        <h1 className="text-white text-2xl font-bold absolute top-3">{data?.name}</h1>
                        <h1 className="text-white text-2xl font-bold absolute top-12">{"Try Your Luck Right Now!"}</h1>
                    </div>
                </div>

                <div className='px-4 flex gap-2 items-center'>
                    <div className="w-2 h-2  bg-purple-600 rounded-full animate-ping"></div>
                    <p className='font-medium'>Time Remianing : 13 mins</p>
                </div>

                <div className='h-[60vh] my-8 p-2 flex flex-col items-center'>
                    {component}
                </div>
            </div>

        </>
    )
}

export default Game