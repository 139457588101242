import React from 'react'
import Dropdown from '../components/dropdown/Dropdown'
import Table from '../components/tables/Table'
import BrowserTitleBar from '../components/titilebar/BrowserTitleBar'


function Transection() {
    return (
        <>
        <BrowserTitleBar title={"Transection"}/>
            <div className='p-3'>

                <Dropdown />

                <div class="relative overflow-x-auto p-5">
                    <Table
                        head={`
                     <tr>
                                <th scope="col" class="px-6 py-3">
                                    Date
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Amount
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Status
                                </th>
                            </tr>`}

                        body={`<tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                            <th scope="row" class="px-6 py-4">
                                12/8/23
                            </th>
                            <td class="px-6 py-4">
                                1500/-
                            </td>
                            <td class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                Done
                            </td>
                        </tr>
                        <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                            <th scope="row" class="px-6 py-4">
                                15/3/24
                            </th>
                            <td class="px-6 py-4">
                                3000/-
                            </td>
                            <td class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                In Progress
                            </td>
                        </tr>`}
                    />
                </div>

            </div>
        </>
    )
}

export default Transection