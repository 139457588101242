import React, { useState } from 'react'
import AmountInput from '../inputs/AddAmount';
import Table from '../tables/Table';

function Patti() {
    const [number, setNumber] = useState('');
    const [amount, setAmount] = useState('');
    const [error, setError] = useState('');
    const [res, setRes] = useState([]);

    const [useDigits, setDigits] = useState([]);

    const handelUpdateNumber = (value) => {
        if (number.length < 3) {
            if (number.length > 0) {
                let last_digit = parseInt(number.charAt(number.length - 1));
                if (parseInt(value) < last_digit) {
                    setError('invalid number!');
                    return;
                }
            }
            let num = number + value;
            setNumber(num);
            setError('');
        }
    }

    const add = () => {
        if (useDigits.length > 0 && useDigits.includes(number)) {
            setError('Digit Already Used!');
            return;
        }
        if (!amount || !number) {
            setError('Amount and Number both are needed!');
            return;
        }
        if (amount && number) {
            setRes(p => [...p, {
                id: Math.floor(Date.now() / 1000),
                digit: number,
                amount: amount
            }])
            setAmount('');
            setNumber('');
            setError('');
            setDigits(p => [...p, number]);
        }
    }

    const removeFromTable = (id) => {
        const data = [...res]
        const newRes = data.filter(d => d?.id !== id);
        setRes(newRes);
    }

    const backspace = () => {
        if (number.length > 0) {
            let newNumber = number.slice(0, -1);
            setNumber(newNumber);
            setError('');
        }
    }

    return (
        <>
            <div className='flex gap-4 items-end h-10'>
                <div className=''>
                    <p className="text-center text-xl  text-gray-600">{(number.length === 1 || number.length > 1) && number[0]}</p>
                    <div className='h-1 w-10 text-center bg-purple-500'></div>
                </div>

                <div>
                    <p className="text-center text-xl  text-gray-600">{(number.length === 2 || number.length > 2) && number[1]}</p>
                    <div className='h-1 w-10 text-center bg-purple-500'></div>
                </div>

                <div>
                    <p className="text-center text-xl  text-gray-600">{number.length === 3 && number[2]}</p>
                    <div className='h-1 w-10 text-center bg-purple-500'></div>
                </div>
            </div>

            <AmountInput setAmount={setAmount} amount={amount} add={add} />
            <p className="text-center text-md text-red-600">{error && error}</p>

            <div className='flex gap-2 my-2 flex-wrap justify-evenly p-6'>

                <button
                    type="button"
                    className="inline-block w-20 h-10 rounded border-2 border-purple-600 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-purple-600 shadow-primary-3 transition duration-150 ease-in-out "
                    data-twe-ripple-init=""
                    data-twe-ripple-color="light"
                    onClick={() => handelUpdateNumber(1)}
                >
                    1
                </button>

                <button
                    type="button"
                    className="inline-block w-20 h-10 rounded border-2 border-purple-600 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-purple-600 shadow-primary-3 transition duration-150 ease-in-out "
                    data-twe-ripple-init=""
                    data-twe-ripple-color="light"
                    onClick={() => handelUpdateNumber(2)}
                >
                    2
                </button>

                <button
                    type="button"
                    className="inline-block w-20 h-10 rounded border-2 border-purple-600 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-purple-600 shadow-primary-3 transition duration-150 ease-in-out "
                    data-twe-ripple-init=""
                    data-twe-ripple-color="light"
                    onClick={() => handelUpdateNumber(3)}
                >
                    3
                </button>

                <button
                    type="button"
                    className="inline-block w-20 h-10 rounded border-2 border-purple-600 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-purple-600 shadow-primary-3 transition duration-150 ease-in-out "
                    data-twe-ripple-init=""
                    data-twe-ripple-color="light"
                    onClick={() => handelUpdateNumber(4)}
                >
                    4
                </button>

                <button
                    type="button"
                    className="inline-block w-20 h-10 rounded border-2 border-purple-600 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-purple-600 shadow-primary-3 transition duration-150 ease-in-out "
                    data-twe-ripple-init=""
                    data-twe-ripple-color="light"
                    onClick={() => handelUpdateNumber(5)}
                >
                    5
                </button>

                <button
                    type="button"
                    className="inline-block w-20 h-10 rounded border-2 border-purple-600 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-purple-600 shadow-primary-3 transition duration-150 ease-in-out "
                    data-twe-ripple-init=""
                    data-twe-ripple-color="light"
                    onClick={() => handelUpdateNumber(6)}
                >
                    6
                </button>

                <button
                    type="button"
                    className="inline-block w-20 h-10 rounded border-2 border-purple-600 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-purple-600 shadow-primary-3 transition duration-150 ease-in-out "
                    data-twe-ripple-init=""
                    data-twe-ripple-color="light"
                    onClick={() => handelUpdateNumber(7)}
                >
                    7
                </button>
                <button
                    type="button"
                    className="inline-block w-20 h-10 rounded border-2 border-purple-600 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-purple-600 shadow-primary-3 transition duration-150 ease-in-out "
                    data-twe-ripple-init=""
                    data-twe-ripple-color="light"
                    onClick={() => handelUpdateNumber(8)}
                >
                    8
                </button>

                <button
                    type="button"
                    className="inline-block w-20 h-10 rounded border-2 border-purple-600 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-purple-600 shadow-primary-3 transition duration-150 ease-in-out "
                    data-twe-ripple-init=""
                    data-twe-ripple-color="light"
                    onClick={() => handelUpdateNumber(9)}
                >
                    9
                </button>

                <button
                    type="button"
                    className="inline-block w-20 h-10 rounded border-2 border-purple-600 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-purple-600 shadow-primary-3 transition duration-150 ease-in-out "
                    data-twe-ripple-init=""
                    data-twe-ripple-color="light"
                    onClick={() => backspace()}
                >
                    <i className="fa-solid fa-delete-left"></i>
                </button>
                {res?.length > 0 && <div className='my-2 mb-9'>
                    <Table
                        map={true}
                        head={`
                     <tr>
                                <th scope="col" class="px-6 py-3">
                                    Digit
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Amount
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Delete
                                </th>
                            </tr>`}

                        body={
                            res.map((v, i) =>
                                <tr key={i} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                    <th scope="row" className="px-6 py-4">
                                        {v?.digit}
                                    </th>
                                    <td className="px-6 py-4">
                                        {v?.amount}
                                    </td>
                                    <td className="px-6 py-4 font-medium">
                                        <i className="fa-solid fa-trash text-red-600"
                                            style={{ fontSize: "19px" }}
                                            onClick={() => removeFromTable(v?.id)}
                                        ></i>
                                    </td>
                                </tr>
                            )
                        }
                    />

                    <button
                        type="button"
                        className="inline-block w-full mb-3 h-10 rounded bg-purple-600 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-primary-3 transition duration-150 ease-in-out hover:bg-primary-accent-300 hover:shadow-primary-2 focus:bg-primary-accent-300 focus:shadow-primary-2 focus:outline-none focus:ring-0 active:bg-primary-600 active:shadow-primary-2 dark:shadow-black/30 dark:hover:shadow-dark-strong dark:focus:shadow-dark-strong dark:active:shadow-dark-strong"
                        data-twe-ripple-init=""
                        data-twe-ripple-color="light"
                    >
                        PLAY GAME
                    </button>
                </div>}
            </div>

        </>)
}

export default Patti