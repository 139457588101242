import React, { useEffect, useState } from 'react'
import Card from '../components/gametypecards/Card'
import Loader from '../components/loader/Loader';
import { useLocation,useNavigate } from 'react-router-dom';
import { decrypt } from '../utils/crypt';

function GameTypesArea() {

  const location = useLocation();
  const navigate=useNavigate();
  const [gametypes, setGameTypes] = useState([]);
  const [gameid, setGameid] = useState('');

  useEffect(() => {
    try {
      const {  game_types,id } = location.state;
      setGameTypes(game_types);
      setGameid(id);
    } catch (error) {
      console.log(error)
      navigate("/")
    }
  }, [location.state])

  return (
    <>
      <div className='h-[80vh] overflow-y-scroll flex items-center justify-center p-4 flex-wrap gap-x-2'>
        {gametypes?.length>0 ? 
          gametypes?.map((v,i)=>
            <Card key={i} data={v}/>
          )
        : <Loader/>}
      </div>
    </>
  )
}

export default GameTypesArea