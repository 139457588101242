import React from 'react'

function Card({bg,textcolor,onclick,text}) {
  return (
    <>
        <div onClick={onclick && onclick} className={"cursor-pointer w-36 h-20 rounded-md shadow-xl flex justify-center items-center ".concat(bg)}>
          <span className={textcolor.concat(' text-sm text-center p-3')}>{text}</span>
        </div>
    </>
  )
}

export default Card