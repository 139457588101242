import React, { useState } from 'react'
import { getCookie } from '../utils/cookies'
import Login from '../pages/Login'
import { AuthContext } from '../context/authContext'
import useAuth from '../hooks/useAuth';


function ProtectedRoute({children}) {

    const[isLogin,setLogin]=useAuth();

    const values={
        isLogin,setLogin
      }

  if(isLogin){
    return  <AuthContext.Provider value={values}>{children}</AuthContext.Provider>
  }

  return (
    <AuthContext.Provider value={values}><Login/></AuthContext.Provider>
  )
}

export default ProtectedRoute