import React from 'react'

function HomeBanner() {
  return (
    <div className="relative mx-auto rounded-lg my-2 shadow-xl">
    <div className="bg-white-100 p-4 rounded-md">
        {/* <h1 class="font-bold text-xl mb-2">Border gradient example</h1> */}
        <p className='text-red-700 font-bold'>1) If there is no bid on the repeat house bet then you cannot play the subsequent bets.

        2) In a repeat bet, all houses together can bet three times the amount of money bet in the next bet. (Max 10000)

        3) Betting outside the rules will not result in winning money. </p>
    </div>
</div>
  )
}

export default HomeBanner