import React from 'react'
import Dropdown from '../components/dropdown/Dropdown'
import ResultCard from '../components/results/ResultCard'
import BrowserTitleBar from '../components/titilebar/BrowserTitleBar'

function Result() {
  return (
    <>
    <BrowserTitleBar title={"Result"}/>
    <div className='h-[80vh] w-full p-3'>
      <Dropdown/>
      <ResultCard/>
      <ResultCard/>
      <ResultCard/>
      <ResultCard/>
      <ResultCard/>
      <ResultCard/>
      <ResultCard/>
      <div className='h-16'></div>
    </div>
    
    </>
  )
}

export default Result