import { Route, createBrowserRouter, createRoutesFromElements } from "react-router-dom";
import App from "../App";
import AddMoney from "../pages/AddMoney";
import Home from "../pages/Home";
import Withdraw from "../pages/Withdraw";
import Result from "../pages/Result";
import PlaySectionArea from "../pages/PlaySectionArea";
import Share from "../pages/Share";
import Reffer from "../pages/Reffer";
import FeedBack from "../pages/FeedBack";
import GameTypesArea from "../pages/GameTypesArea";
import Profile from "../pages/Profile";
import GameRules from "../pages/GameRules";
import Transection from "../pages/Transection";
import Game from "../pages/Game";
import GameTiming from "../pages/GameTiming";
import Bankdetails from "../pages/Bankdetails";
import BidHistory from "../pages/BidHistory";
import Register from "../pages/Register";
import Login from "../pages/Login";
import Page404 from "../pages/Page404";
import ProtectedRoute from "./ProtectedRoute";


const router = createBrowserRouter(
	createRoutesFromElements(
		<>
			<Route path="/" element={<ProtectedRoute><App /></ProtectedRoute>}>
				<Route path="/" index element={<Home />} />
				<Route path="/play-section/:urlid" element={<PlaySectionArea />} />
				<Route path="/game-types/:urlid" element={<GameTypesArea />} />
				<Route path="/game/:urlid" element={<Game />} />
				{/* <Route path="/add-money" element={<AddMoney />} />
				<Route path="/withdraw" element={<Withdraw />} />
				<Route path="/bank-details" element={<Bankdetails />} /> */}
				<Route path="/share" element={<Share />} />
				{/* <Route path="/profile" element={<Profile />} /> */}
				<Route path="/game-rules" element={<GameRules />} />
				<Route path="/game-timing" element={<GameTiming />} />
				<Route path="/bid-history" element={<BidHistory />} />
				{/* <Route path="/transection-history" element={<Transection />} /> */}
				<Route path="/reffer-and-earn" element={<Reffer />} />
				{/* <Route path="/feedback" element={<FeedBack />} /> */}
				<Route path="/result" element={<Result />} />
				<Route path="*" element={<Page404 />} />
			</Route>
			<Route path="/register" element={<Register />} />
			<Route path="/login" element={<Login />} />

		</>
	)
);

export default router;
