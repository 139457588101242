import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

function BottomNavbar() {
  const location=useLocation();
  const[currentpath,setCurrentpath]=useState('');

  useEffect(()=>{
    if(location.pathname==='/'){
      setCurrentpath('home')
    }
    else if(location.pathname==='add-money'){
      setCurrentpath('addmoney')
    }
    else if(location.pathname==='withdraw'){
      setCurrentpath('withdraw')
    }
    else if(location.pathname==='result'){
      setCurrentpath('result');
    }
  },[location])

  return (

    <>
      <div className="fixed bottom-0 bg-[#f1eef5] left-0 z-50 w-full h-16  border-t-2 border-gray-200 dark:bg-gray-700 dark:border-gray-600">
        <div className="grid h-full max-w-lg grid-cols-4 mx-auto font-medium">
          <Link
            to={"/"}
            className="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group"
          >
            <i className="fa-solid fa-house text-purple-900 dark:text-gray-400 group-hover:text-blue-600 dark:group-hover:text-blue-500" style={{ "fontSize": "20px" }}></i>
            <span className="text-sm text-purple-900 dark:text-gray-400 group-hover:text-blue-600 dark:group-hover:text-blue-500">
              Home
            </span>
          </Link>
          <Link
            to={"/game-rules"}
            className="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group"
          >
            <i className="fa-solid fa-scale-balanced text-purple-900 dark:text-gray-400 group-hover:text-blue-600 dark:group-hover:text-blue-500" style={{ "fontSize": "20px" }}></i>
            <span className="text-sm text-purple-900 dark:text-gray-400 group-hover:text-blue-600 dark:group-hover:text-blue-500">
              Rules
            </span>
          </Link>
          <Link
            to={"/result"}
            className="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group"
          >
            <i className="fa-solid fa-magnifying-glass text-purple-900 dark:text-gray-400 group-hover:text-blue-600 dark:group-hover:text-blue-500" style={{ "fontSize": "20px" }}></i>
            <span className="text-sm text-purple-900 dark:text-gray-400 group-hover:text-blue-600 dark:group-hover:text-blue-500">
              Result
            </span>
          </Link>
          <Link
            to={"/game-timing"}
            className="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group"
          >
            <i className="fa-solid fa-hourglass-start text-purple-900 dark:text-gray-400 group-hover:text-blue-600 dark:group-hover:text-blue-500" style={{ "fontSize": "20px" }}></i>
            <span className="text-sm text-purple-900 dark:text-gray-400 group-hover:text-blue-600 dark:group-hover:text-blue-500">
            Timing
            </span>
          </Link>
        </div>
      </div>

    </>


  )
}

export default BottomNavbar