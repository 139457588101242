import React from 'react'

function ResultCard() {
    return (
        <>

            <div className='w-full my-2 bg-gray-100 shadow-md p-2 flex flex-col'>
                <div className='w-full border-b-2 border-purple-400 flex justify-between items-start'>
                    <p>Kolkata Fatafat</p>
                    <span><i className="fa-solid fa-calendar-days text-purple-400" ></i> 23-04-2024</span>
                </div>
                
                <div className='w-full flex gap-1 my-2'>
                    <div className='rounded border-2 border-purple-600 h-16 w-16 flex flex-col justify-between items-center'>
                        <p>5</p>
                        <p>159</p>
                    </div>
                    <div className='rounded border-2 border-purple-600 h-16 w-16 flex flex-col justify-between items-center'>
                        <p>-</p>
                        <p>-</p>
                    </div>
                    <div className='rounded border-2 border-purple-600 h-16 w-16 flex flex-col justify-between items-center'>
                        <p>-</p>
                        <p>-</p>
                    </div>
                    <div className='rounded border-2 border-purple-600 h-16 w-16 flex flex-col justify-between items-center'>
                        <p>-</p>
                        <p>-</p>
                    </div>
                    <div className='rounded border-2 border-purple-600 h-16 w-16 flex flex-col justify-between items-center'>
                        <p>-</p>
                        <p>-</p>
                    </div>
                    <div className='rounded border-2 border-purple-600 h-16 w-16 flex flex-col justify-between items-center'>
                        <p>-</p>
                        <p>-</p>
                    </div>
                    <div className='rounded border-2 border-purple-600 h-16 w-16 flex flex-col justify-between items-center'>
                        <p>-</p>
                        <p>-</p>
                    </div>
                    <div className='rounded border-2 border-purple-600 h-16 w-16 flex flex-col justify-between items-center'>
                    <p>-</p>
                    <p>-</p>                        
                    </div>
                </div>
            </div>

        </>
    )
}

export default ResultCard