export function generatePermutationsAndCombinations(number) {
    // Convert number to array of digits
    const digits = number.toString().split('').map(Number);

    // Array to store permutations
    const permutations = [];

    // Recursive function to generate permutations
    function permute(arr, prefix = []) {
        if (prefix.length === 3) {
            // Check if the generated permutation satisfies the condition
            if (prefix[0] < prefix[1] && prefix[1] < prefix[2]) {
                permutations.push(prefix.join('')); // Add the permutation to the array
            }
            return;
        }
        for (let i = 0; i < arr.length; i++) {
            // Skip duplicate digits
            if (i > 0 && arr[i] === arr[i - 1]) continue;
            permute(arr.slice(0, i).concat(arr.slice(i + 1)), prefix.concat(arr[i]));
        }
    }

    permute(digits);
    
    return permutations; // Return the generated permutations
}
