import axios from 'axios';

const baseurl = 'http://192.168.29.33:5000'

export const axiosInstance = axios.create({
  baseURL: baseurl

})

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

axiosInstance?.interceptors?.response?.use(async(res) => {
  console.log("Axios response:", res);
  // await sleep(500);
  return res;
}, err => {
  console.log("Axios error:", err);
  throw err;
});
