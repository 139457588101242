import React, { useEffect } from 'react'
import './landing.css';
import logo from '../../images/logo2.png';

function Landing() {
    useEffect(() => {
        const logo = document.querySelector(".logo-container");
    
        // Add animation class when component mounts
        logo.classList.add("animate-scale-up");
    
        // Remove animation class after animation completes
        const onAnimationEnd = () => {
          logo.classList.add("animate-scale-down");
        };
    
        logo.addEventListener("animationend", onAnimationEnd);
    
        return () => {
          // Cleanup: Remove event listener
          logo.removeEventListener("animationend", onAnimationEnd);
        };
      }, []); // Empty dependency array to run the effect only once when the component mounts
    
      return (
        <div className="flex flex-col justify-center bg-[#f0ebf7] items-center h-[100vh]">
          <div className="logo-container">
            <img src={logo} alt="Logo" className="logo h-22 w-28" />
          </div>
          <p className='my-4 font-extrabold text-[#7b16e6]'>WELCOME TO LUCKEY 7</p>
        </div>
      );
}

export default Landing