import React, { createContext, useContext, useEffect, useState } from 'react'
import BottomNavbar from './components/navbar/BottomNavbar'
import UpperNavbar from './components/navbar/UpperNavbar'
import { Outlet } from 'react-router-dom'
import Landing from './components/landing/Landing'
import { AuthContext } from './context/authContext'
import { getCookie, setCookie } from './utils/cookies'
import Login from './pages/Login'
import axios from 'axios'


const AppContext=createContext();

function App() {

  const [landing, setLanding] = useState(true);
  const [redirected,setRedirected]=useState(false);

  const authContext = useContext(AuthContext);

  useEffect(() => {
    setTimeout(() => {
      setLanding(false);
    }, 1500);
    // test();
    const url = new URL(window.location.href);
    const token = url.searchParams.get('token');
    if(token){
      setCookie('auth_token',token);
      setRedirected(true);
    }
    
  }, [])

  // const test = async () => {
  //   const res = await axios.get('http://localhost/api/v1/test/', {
  //     headers: {
  //       "Content-Type": "application/json",
  //       "TOKEN":getCookie('auth_token')
  //     }
  //   });
  //   console.log(res);
  // }

  const value={redirected:redirected,setRedirected:setRedirected}

  return (
    <>
      {!landing ?
        <>
          <AppContext.Provider value={value}>
            {!redirected&&<UpperNavbar />}
            <Outlet />
            {!redirected&&<BottomNavbar />}

            <div className='w-full h-16 bg-transparent'>

            </div>
          </AppContext.Provider>
        </>
        :
        <Landing />}
    </>
  )
}

export default App
