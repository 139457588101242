import React from 'react'
import BrowserTitleBar from '../components/titilebar/BrowserTitleBar'

function GameRules() {
    return (
        <>
        <BrowserTitleBar title={"Game-rules"}/>
        <div className='p-3'>
            <div className="my-5 border-2 border-purple-500">
                <h1 className='text-black text-center text-2xl m-5'>GAME RULES</h1>
            </div>
            <h2 className="text-center text-gray-400 my-5 text-2xl">NOTICE BOARD FOR GAME RULES</h2>
            <div className='w-full my-5 bg-gray-100 shadow-md p-5 flex flex-col'>
                <p className="text-purple-400 text-center">MINIMUM DEPOSIT - RS. 50</p>
                <p className="text-purple-400 text-center">MAXIMUM WITHDRAWAl - RS. 50</p>

            </div>
            <div className='w-full my-5 bg-gray-100 shadow-md p-5 flex flex-col'>
                <p className="text-purple-400 text-center font-bold">*NOTE: CASH WITHDRAWALS ARE MADE FROM MONDAY TO SATURDAY FROM 9 AM TO 9 PM AND FROM 9 AM TO 3 PM ON SUNDAYS</p>
            </div>
            <div className='w-full my-5 bg-gray-100 shadow-md p-5 flex flex-col'>
                <div className='w-full border-b-2 border-purple-400 flex justify-between items-start'>
                    <p className="text-purple-400">KOLKATA FATAFAT</p>
                    <span className="text-purple-400">SINGLE</span>
                </div>

                <div className='w-full gap-1 my-2'>
                    <p className='text-purple-400'>KOLKATA FATAFAT  Rs. 10 = Rs. 90</p>
                    <br />
                    <p className='text-purple-400'>Minimum Bet - Rs. 1</p>
                    <p className='text-purple-400'>Maximum Bet - Rs. 100000</p>
                </div>
            </div>
            <div className='w-full my-5 bg-gray-100 shadow-md p-5 flex flex-col'>
                <div className='w-full border-b-2 border-purple-400 flex justify-between items-start'>
                    <p className="text-purple-400">KOLKATA FATAFAT</p>
                    <span className="text-purple-400">PATTI</span>
                </div>

                <div className='w-full gap-1 my-2'>
                    <p className='text-purple-400'>KOLKATA FATAFAT  Rs. 10 = Rs. 1000</p>
                    <br />
                    <p className='text-purple-400'>Minimum Bet - Rs. 1</p>
                    <p className='text-purple-400'>Maximum Bet - Rs. 1000</p>
                </div>
            </div>
            <div className='w-full my-5 bg-gray-100 shadow-md p-5 flex flex-col'>
                <div className='w-full border-b-2 border-purple-400 flex justify-between items-start'>
                    <p className="text-purple-400">KOLKATA FATAFAT</p>
                    <span className="text-purple-400">JORI</span>
                </div>

                <div className='w-full gap-1 my-2'>
                    <p className='text-purple-400'>KOLKATA FATAFAT  Rs. 10 = Rs. 800</p>
                    <br />
                    <p className='text-purple-400'>Minimum Bet - Rs. 1</p>
                    <p className='text-purple-400'>Maximum Bet - Rs. 100000</p>
                </div>
            </div>
        </div>

        </>
    )
}

export default GameRules