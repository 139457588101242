import React from 'react'

function Table({ head, body, map = false }) {
    return (
        <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead dangerouslySetInnerHTML={{ __html: head }} class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            </thead>
            {!map ?
                <tbody dangerouslySetInnerHTML={{ __html: body }}>
                </tbody>
                :
                <tbody>
                    {body}
                </tbody>
            }
        </table>
    )
}

export default Table