import React from 'react'
import logo from '../images/logo2.png';
import BrowserTitleBar from '../components/titilebar/BrowserTitleBar';

function Share() {
    return (
        <>
        <BrowserTitleBar title={"Share"}/>
            <div className="justify-center items-center flex flex-col h-[80vh] bg-white">
                <div className="w-full max-w-xs m-auto rounded p-5">
                    <header className="flex flex-col justify-center items-center ">
                        <img
                            className="w-20 mx-auto mb-5"
                            src={logo}
                        />
                        <br />
                        <p className="text-purple-600">Please Share this application with your close ones</p>
                        <br />
                    </header>
                    <div>
                        <button
                            className="w-full bg-purple-600 hover:bg-purple-600 text-white font-bold py-1 px-2 rounded"
                            type="submit"
                        >
                           <i className="fa-solid fa-share-nodes mr-1"></i> Share
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Share