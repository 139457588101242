import { axiosInstance } from "../axios";

const suburl='/json/game.json'

export const getGames=async()=>{
    return await axiosInstance.get(suburl);
}



