import React, { useState } from 'react'

function AmountInput({setAmount,amount,add}) {
    const [error, setError] = useState('');
  
    const handleChange = (event) => {
      const value = event.target.value;
      const isValid = /^[0-9]+(?:\.[0-9]+)?$/.test(value) && parseFloat(value) >= 0;
      if (isValid || value === '') {
        setAmount(value);
        setError('');
      } else {
        setError('Please enter a valid amount');
      }
    };

    return (
        <div className='flex my-6  flex-col justify-start items-start '>
        <div className='flex gap-3'>
            <input
                type="number"
                className="border-b border-purple-500 focus:border-blue-500 outline-none"
                placeholder="Enter Amount..."
                value={amount && amount}
                onChange={handleChange}
            />
            <button
                type="button"
                className="inline-block w-20 h-10 rounded bg-purple-600 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-primary-3 transition duration-150 ease-in-out hover:bg-primary-accent-300 hover:shadow-primary-2 focus:bg-primary-accent-300 focus:shadow-primary-2 focus:outline-none focus:ring-0 active:bg-primary-600 active:shadow-primary-2 dark:shadow-black/30 dark:hover:shadow-dark-strong dark:focus:shadow-dark-strong dark:active:shadow-dark-strong"
                data-twe-ripple-init=""
                data-twe-ripple-color="light"
                onClick={add}
            >
                ADD
            </button>
        </div>
        <p className="text-center text-md text-red-600">{error && error }</p>
        </div>
    )
}

export default AmountInput