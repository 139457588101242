import React, { useEffect, useState, useContext } from 'react'
import { Link,  useNavigate } from 'react-router-dom'
import BrowserTitleBar from '../components/titilebar/BrowserTitleBar';
import { setCookie } from '../utils/cookies'
import { AuthContext } from '../context/authContext'
import axios from 'axios';

function Register() {

  const [register, setRegister] = useState({
    fullname: "",
    email: "",
    phone: "",
    refer_code: "",
    password: "",
    re_password: ""
  })
  const [error, setError] = useState("");


  const authContext = useContext(AuthContext);
  const nagivate = useNavigate();
  

  
  const handleRegister = async () => {
    try {
      if(register.fullname && register.password)
      {const response = await axios.post('http://localhost/api/v1/register/', register,{
        headers:{
          "Content-Type":"application/json"
        }
      });
      console.log('Registration successful!', response.data);
      setCookie('auth_token',response.data?.token);    
      authContext?.setRegister(true);
      nagivate("/");}
    } catch (error) {
      console.error('Registration Failed:', error);
    }
  };


  return (
    <>
      <BrowserTitleBar title={"Register"} />
      <section className="py-10 bg-gray-50 sm:py-16 lg:py-24">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="max-w-2xl mx-auto text-center">
            <h2 className="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl">
              Create Account
            </h2>
          </div>
          <div className="relative max-w-md mx-auto mt-8 md:mt-16">
            <div className="overflow-hidden bg-white rounded-md shadow-md">
              <div className="px-4 py-6 sm:px-8 sm:py-7">
                <div className="space-y-5">
                  <div>
                    <label
                      htmlFor=""
                      className="text-base font-medium text-gray-900"
                    >
                      {" "}
                      First &amp; Last name{" "} <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-2.5 relative text-gray-400 focus-within:text-gray-600">
                      <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <svg
                          className="w-5 h-5"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                          />
                        </svg>
                      </div>
                      <input
                        type="text"
                        name=""
                        required
                        placeholder="Enter your full name"
                        value={register?.fullname}
                        onChange={(e)=>setRegister(p=>({...p,fullname:e.target.value}))}
                        className="block w-full py-4 pl-10 pr-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md focus:outline-none focus:border-blue-600 caret-blue-600"
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor=""
                      className="text-base font-medium text-gray-900"
                    >
                      {" "}
                      Email address{" "} <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-2.5 relative text-gray-400 focus-within:text-gray-600">
                      <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <svg
                          className="w-5 h-5"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
                          />
                        </svg>
                      </div>
                      <input
                        type="email"
                        name=""
                        required
                        value={register?.email}
                        onChange={(e)=>setRegister(p=>({...p,email:e.target.value}))}
                        placeholder="Enter email to get started"
                        className="block w-full py-4 pl-10 pr-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md focus:outline-none focus:border-blue-600 caret-blue-600"
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor=""
                      className="text-base font-medium text-gray-900"
                    >
                      {" "}
                      Phone{" "} <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-2.5 relative text-gray-400 focus-within:text-gray-600">
                      <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" id="phone">
                          <path d="M27.308,20.649l-2.2-2.2a3.521,3.521,0,0,0-4.938-.021,2.152,2.152,0,0,1-2.729.267A15.026,15.026,0,0,1,13.3,14.562a2.181,2.181,0,0,1,.284-2.739A3.521,3.521,0,0,0,13.553,6.9l-2.2-2.2a3.514,3.514,0,0,0-4.961,0l-.633.634c-3.3,3.3-3.053,10.238,3.813,17.1,4.14,4.141,8.307,5.875,11.686,5.875a7.5,7.5,0,0,0,5.418-2.061l.634-.634A3.513,3.513,0,0,0,27.308,20.649ZM25.894,24.2l-.634.634c-2.6,2.6-8.339,2.125-14.276-3.813S4.571,9.34,7.171,6.74L7.8,6.107a1.511,1.511,0,0,1,2.133,0l2.2,2.2a1.511,1.511,0,0,1,.021,2.11,4.181,4.181,0,0,0-.531,5.239,17.01,17.01,0,0,0,4.713,4.706,4.179,4.179,0,0,0,5.231-.517,1.512,1.512,0,0,1,2.118.013l2.2,2.2A1.51,1.51,0,0,1,25.894,24.2Z" />
                        </svg>




                      </div>
                      <input
                        type="number"
                        name=""
                        required
                        value={register?.phone}
                        onChange={(e)=>setRegister(p=>({...p,phone:e.target.value}))}
                        placeholder="Enter number to get started"
                        className="block w-full py-4 pl-10 pr-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md focus:outline-none focus:border-blue-600 caret-blue-600"
                      />
                    </div>
                  </div>

                  <div>
                    <label
                      htmlFor=""
                      className="text-base font-medium text-gray-900"
                    >
                      {" "}
                      Refer Code (Optional){" "}
                    </label>
                    <div className="mt-2.5 relative text-gray-400 focus-within:text-gray-600">
                      <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <svg className='w-5 h-5'
                          id="Layer_1"
                          data-name="Layer 1"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 122.88 86.63"
                        >
                          <defs>
                            <style
                              dangerouslySetInnerHTML={{
                                __html:
                                  ".cls-1{fill:#262626;}.cls-1,.cls-2{fill-rule:evenodd;}.cls-2{fill:#d8453e;}"
                              }}
                            />
                          </defs>
                          <title>refer</title>
                          <path
                            className="cls-1"
                            d="M23.79,50.25c3.44,10.12,17.82,10.5,21.06,0,3.81,3.43,12.63,4.11,16.83,6.44s5.73,6.06,6.08,10.6l.29,2.39a17.43,17.43,0,0,1-2.61,1.67A18.13,18.13,0,0,1,54.75,73a6.67,6.67,0,0,0-7.26,5H4c-2.54-.2-3.82-1.51-4-3.77l.87-7a14.1,14.1,0,0,1,2.6-7.64,11.21,11.21,0,0,1,3.49-3c5-2.77,12.67-2.7,16.83-6.44Zm-3-18.57a2.75,2.75,0,0,0-1.22.38,1.33,1.33,0,0,0-.42.49,1.8,1.8,0,0,0-.13.8A7.64,7.64,0,0,0,20.56,37l0,0h0l3.26,5.17A27.68,27.68,0,0,0,28.2,48a8.75,8.75,0,0,0,6.14,2.47,9,9,0,0,0,6.49-2.59,28.63,28.63,0,0,0,4.48-6.1l3.66-6c.75-1.69,1-2.73.73-3.23-.14-.28-.64-.39-1.46-.34a3.76,3.76,0,0,1-2-.07l1.48-4.17C41,27.81,36.42,26.64,31,23.17c-1.79-1.13-2.32-2.44-4.1-2.31a6,6,0,0,0-3.37,1.83,8.66,8.66,0,0,0-1.94,3.82l1.08,5.21a3.66,3.66,0,0,1-1.85,0ZM50,30.18a2.56,2.56,0,0,1,1.66,1.36c.55,1.1.33,2.74-.7,5.09h0l-.07.12-3.71,6.11a30.15,30.15,0,0,1-4.85,6.56,11,11,0,0,1-8,3.17,10.84,10.84,0,0,1-7.58-3A29.57,29.57,0,0,1,22,43.4l-3.25-5.18a9.51,9.51,0,0,1-1.87-4.8,3.94,3.94,0,0,1,.34-1.77,3.21,3.21,0,0,1,1.18-1.36,4.08,4.08,0,0,1,.86-.44,58.35,58.35,0,0,1-.14-7.62,13.18,13.18,0,0,1,.4-2.12,12.49,12.49,0,0,1,5.5-7,14.84,14.84,0,0,1,3-1.44c6.25-2.26,14.54-1,19,3.78a12.59,12.59,0,0,1,3.19,8L50,30.18Z"
                          />
                          <path
                            className="cls-1"
                            d="M78,39.68c3.44,10.12,17.82,10.5,21.06,0,3.81,3.43,12.63,4.12,16.83,6.45s5.73,6,6.08,10.6l.87,8.86c0,.18-7.42.26-16.42.28l0-6.45a6.65,6.65,0,0,0-6.62-6.63l-25.67-.13a6.78,6.78,0,0,0-3,.71A12.2,12.2,0,0,0,67,49.71a34.54,34.54,0,0,0-7.29-2.61,13.23,13.23,0,0,1,1.5-1c5-2.78,12.67-2.7,16.83-6.45Zm-3-18.56a2.54,2.54,0,0,0-1.21.38,1.17,1.17,0,0,0-.43.48,1.8,1.8,0,0,0-.13.8,7.57,7.57,0,0,0,1.52,3.69l0,0h0l3.26,5.17a27.16,27.16,0,0,0,4.36,5.72,8.72,8.72,0,0,0,6.14,2.48,9,9,0,0,0,6.49-2.59,28.45,28.45,0,0,0,4.48-6.11l3.66-6c.75-1.7,1-2.74.73-3.23-.14-.29-.64-.39-1.46-.35a3.65,3.65,0,0,1-2-.06L102,17.33c-6.71-.08-11.29-1.25-16.72-4.72-1.79-1.14-2.32-2.44-4.1-2.31a6,6,0,0,0-3.37,1.82,8.66,8.66,0,0,0-1.94,3.82l1.08,5.21a3.56,3.56,0,0,1-1.85,0Zm29.14-1.5A2.58,2.58,0,0,1,105.86,21c.55,1.11.33,2.75-.7,5.09h0s0,.08-.07.13l-3.71,6.11a30.39,30.39,0,0,1-4.85,6.56,11.07,11.07,0,0,1-8,3.16A10.85,10.85,0,0,1,81,39a29.06,29.06,0,0,1-4.72-6.15L73,27.66a9.57,9.57,0,0,1-1.87-4.81,3.87,3.87,0,0,1,.34-1.76,3.23,3.23,0,0,1,1.18-1.37,3.64,3.64,0,0,1,.86-.43,58.53,58.53,0,0,1-.14-7.63,13.06,13.06,0,0,1,.4-2.11,12.45,12.45,0,0,1,5.5-7,14.32,14.32,0,0,1,3-1.44c6.25-2.27,14.54-1,19,3.77a12.61,12.61,0,0,1,3.19,8l-.21,6.77Z"
                          />
                          <path
                            className="cls-2"
                            d="M99.77,59.45l-25.66-.13,6.42,6.42c-6.4,9.75-14.62,14.28-25.61,13,13.47,11.78,28.2,9.25,38.43-.17l6.55,6.55-.13-25.66Z"
                          />
                        </svg>
                      </div>
                      <input
                        type="text"
                        name=""

                        value={register?.refer_code}
                        onChange={(e) => setRegister(p => ({ ...p, refer_code: e.target.value }))}
                        placeholder="Enter Refer Code"
                        className="block w-full py-4 pl-10 pr-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md focus:outline-none focus:border-blue-600 caret-blue-600"
                      />
                    </div>
                  </div>

                  <div>
                    <label
                      htmlFor=""
                      className="text-base font-medium text-gray-900"
                    >
                      {" "}
                      Password{" "} <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-2.5 relative text-gray-400 focus-within:text-gray-600">
                      <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <svg
                          className="w-5 h-5"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M12 11c0 3.517-1.009 6.799-2.753 9.571m-3.44-2.04l.054-.09A13.916 13.916 0 008 11a4 4 0 118 0c0 1.017-.07 2.019-.203 3m-2.118 6.844A21.88 21.88 0 0015.171 17m3.839 1.132c.645-2.266.99-4.659.99-7.132A8 8 0 008 4.07M3 15.364c.64-1.319 1-2.8 1-4.364 0-1.457.39-2.823 1.07-4"
                          />
                        </svg>
                      </div>
                      <input
                        type="password"
                        name=""
                        required
                        value={register?.password}
                        onChange={(e)=>setRegister(p=>({...p,password:e.target.value}))}
                        placeholder="Enter your password"
                        className="block w-full py-4 pl-10 pr-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md focus:outline-none focus:border-blue-600 caret-blue-600"
                      />
                    </div>
                  </div>

                  <div>
                    <label
                      htmlFor=""
                      className="text-base font-medium text-gray-900"
                    >
                      {" "}
                      Re Type Password{" "} <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-2.5 relative text-gray-400 focus-within:text-gray-600">
                      <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <svg
                          className="w-5 h-5"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M12 11c0 3.517-1.009 6.799-2.753 9.571m-3.44-2.04l.054-.09A13.916 13.916 0 008 11a4 4 0 118 0c0 1.017-.07 2.019-.203 3m-2.118 6.844A21.88 21.88 0 0015.171 17m3.839 1.132c.645-2.266.99-4.659.99-7.132A8 8 0 008 4.07M3 15.364c.64-1.319 1-2.8 1-4.364 0-1.457.39-2.823 1.07-4"
                          />
                        </svg>
                      </div>
                      <input
                        type="password"
                        name=""
                        required
                        value={register?.re_password}
                        onChange={(e) => setRegister(p => ({ ...p, re_password: e.target.value }))}
                        placeholder="Re Enter your password"
                        className="block w-full py-4 pl-10 pr-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md focus:outline-none focus:border-blue-600 caret-blue-600"
                      />
                    </div>
                    <p className="text-center text-md text-red-600">{error && error}</p>
                  </div>
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      name="agree"
                      className="w-5 h-5 text-green-500 bg-white border-gray-200 rounded"
                      defaultChecked=""
                    />
                    <label
                      htmlFor="agree"
                      className="ml-3 text-sm font-medium text-gray-500"
                    >
                      I agree to Luckey7's{" "}
                      <a
                        href="#"
                        title=""
                        className="text-blue-600 hover:text-blue-700 hover:underline"
                      >
                        Terms of Service
                      </a>{" "}
                      and{" "}
                      <a
                        href="#"
                        title=""
                        className="text-blue-600 hover:text-blue-700 hover:underline"
                      >
                        Privacy Policy
                      </a>
                    </label>
                  </div>
                  <div>
                    <button
                      type="submit"
                      onClick={handleRegister}
                      className="inline-flex items-center justify-center w-full px-4 py-4 text-base font-semibold text-white transition-all duration-200 bg-blue-600 border border-transparent rounded-md focus:outline-none hover:bg-blue-700 focus:bg-blue-700"
                    >
                      Create account
                    </button>
                  </div>
                  <div className="text-center">
                    <p className="text-base text-gray-600">
                      Already have an account?{" "}
                      <Link
                        to={"/login"}
                        title=""
                        className="font-medium text-orange-500 transition-all duration-200 hover:text-orange-600 hover:underline"
                      >
                        Login here
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Register