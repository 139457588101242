import Card from '../components/gamenamecards/Card'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { getGames } from '../service/getGames';
import Loader from '../components/loader/Loader';
import { encrypt } from '../utils/crypt';
import BrowserTitleBar from '../components/titilebar/BrowserTitleBar';
import HomeBanner from '../components/banner/HomeBanner';




function Home() {
  const [game, setGames] = useState([]);
  const navigate = useNavigate();

  const geoToPlaySection = (id) => {
    const enc_id = encrypt(id);
    const game_data = game.filter(data => data?.id === id)
    navigate(`/play-section/${enc_id.replace(/\//g, "")}`, { state: { id: id, game_data: game_data[0] } });
  }

  const get_games = async () => {
    try {
      const games = await getGames();
      console.log(games)
      setGames(games?.data?.games)
    } catch (error) {
      console.log(error)
    }

  }

  useEffect(() => {
    get_games()
  }, [])

  return (
    <>
      <BrowserTitleBar title={"Home"} />
      <div className='h-[80vh] overflow-y-scroll flex justify-center p-4 flex-wrap gap-1 gap-y-0'>
        {/* <div className="shadow-lg w-[100%] shadow-indigo-500/50 rounded-lg flex gap-2 h-32 bg-white p-4 justify-center items-center text-surface dark:bg-surface-dark dark:text-white">
          <Card onclick={() => navigate('/add-money')} text={<span className='flex flex-col justify-center items-center'>Add Money </span>} textcolor={"text-white font-bold"} bg={"bg-green-500"} />
          <Card onclick={() => navigate('/withdraw')} text={"Withdraw"} textcolor={"text-white font-bold"} bg={"bg-red-600"} />
        </div> */}
        <HomeBanner/>
        {game.length > 0 && 
        <div className="flex justify-center flex-wrap shadow-lg shadow-indigo-500/50 rounded-lg p-4 gap-2 bg-white text-surface dark:bg-surface-dark dark:text-white">

          {
            game.length > 0 ?
              game.map((v, i) =>
                <Card onclick={() => geoToPlaySection(v?.id)} key={i} text={v?.name} textcolor={"text-white font-bold"} bg={"bg-purple-600"} />
              )
              :
              <Loader />
          }
        </div>}
      </div>
    </>
  )
}

export default Home