import React, { useState } from 'react';
import AmountInput from '../inputs/AddAmount';
import Table from '../tables/Table';

function JitOpposite() {

    const [number, setNumber] = useState(null);
    const [amount, setAmount] = useState('');
    const [res, setRes] = useState([]);
    const [error, setError] = useState('');

    const [useDigits, setDigits] = useState([]);

    const handleNumberClick = (num) => {
        const tripleNumber = num.toString();
        setNumber(tripleNumber);
    };

    const add = () => {
        if (useDigits.length > 0 && useDigits.includes(number)) {
            setError('Digit Already Used!');
            return;
        }
        if (!amount || !number) {
            setError('Amount and Number both are needed!');
            return;
        }
        if (amount && number) {
            setRes(p => [...p, {
                id: Math.floor(Date.now() / 1000),
                digit: number,
                amount: amount
            }])
            setAmount('');
            setNumber('');
            setError('');
            setDigits(p => [...p, number]);
        }
    }

    const removeFromTable = (id) => {
        const data = [...res]
        const newRes = data.filter(d => d?.id !== id);
        setRes(newRes);
    }

    return (
        <>
            <div>
                <p className="text-center text-xl text-gray-600">{number && number}</p>
                <div className='h-1 w-16 bg-purple-500'></div>
            </div>

            <AmountInput setAmount={setAmount} amount={amount} add={add} />
            <p className="text-center text-md text-red-600">{error && error}</p>

            <div className='flex gap-2 my-2 flex-wrap justify-evenly p-6'>
                {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((num) => (
                    <button
                        key={num}
                        type="button"
                        className="inline-block w-20 h-10 rounded border-2 border-purple-600 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-purple-600 shadow-primary-3 transition duration-150 ease-in-out "
                        data-twe-ripple-init=""
                        data-twe-ripple-color="light"
                        onClick={() => handleNumberClick(num)}
                    >
                        {num}
                    </button>
                ))}
                                {res?.length > 0 && <div className='my-2 mb-9'>
                    <Table
                        map={true}
                        head={`
                     <tr>
                                <th scope="col" class="px-6 py-3">
                                    Digit
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Amount
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Delete
                                </th>
                            </tr>`}

                        body={
                            res.map((v, i) =>
                                <tr key={i} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                    <th scope="row" className="px-6 py-4">
                                        {v?.digit}
                                    </th>
                                    <td className="px-6 py-4">
                                        {v?.amount}
                                    </td>
                                    <td className="px-6 py-4 font-medium">
                                        <i className="fa-solid fa-trash text-red-600"
                                            style={{ fontSize: "19px" }}
                                            onClick={() => removeFromTable(v?.id)}
                                        ></i>
                                    </td>
                                </tr>
                            )
                        }
                    />

                    <button
                        type="button"
                        className="inline-block w-full mb-3 h-10 rounded bg-purple-600 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-primary-3 transition duration-150 ease-in-out hover:bg-primary-accent-300 hover:shadow-primary-2 focus:bg-primary-accent-300 focus:shadow-primary-2 focus:outline-none focus:ring-0 active:bg-primary-600 active:shadow-primary-2 dark:shadow-black/30 dark:hover:shadow-dark-strong dark:focus:shadow-dark-strong dark:active:shadow-dark-strong"
                        data-twe-ripple-init=""
                        data-twe-ripple-color="light"
                    >
                        PLAY GAME
                    </button>
                </div>}
            </div>
        </>
    );

}

export default JitOpposite