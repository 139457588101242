import React from 'react'
import { useNavigate } from 'react-router-dom'

function PlayCards({data,urlid,game_types,id}) {
    const navigate=useNavigate();
    return (
        <>
            <div className="block rounded-lg my-1 bg-white p-6 text-surface shadow-secondary-1 dark:bg-surface-dark dark:text-white">
                <h5 className="mb-2 text-xl font-medium leading-tight">{data?.title}</h5>
                <div className="mb-4 text-base flex gap-1 items-center">
                    <i className="fa-solid fa-clock  text-purple-400"></i>
                     Start Time : {data?.start_time}
                </div>
                <div className="mb-4 text-base flex gap-1 items-center">
                    <i className="fa-solid fa-clock text-purple-400"></i>
                     End Time : {data?.end_time}
                </div>
                {data?.closed ?
                <button
                    type="button"
                    className="inline-block w-40 h-10 rounded border-2 border-purple-600 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-purple-600 shadow-primary-3 transition duration-150 ease-in-out "
                    data-twe-ripple-init=""
                    data-twe-ripple-color="light"
                >
                    BAZAR CLOSED
                </button>

                :

                <button
                    type="button"
                    className="inline-block w-40 h-10 rounded bg-purple-600 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-primary-3 transition duration-150 ease-in-out hover:bg-primary-accent-300 hover:shadow-primary-2 focus:bg-primary-accent-300 focus:shadow-primary-2 focus:outline-none focus:ring-0 active:bg-primary-600 active:shadow-primary-2 dark:shadow-black/30 dark:hover:shadow-dark-strong dark:focus:shadow-dark-strong dark:active:shadow-dark-strong"
                    data-twe-ripple-init=""
                    data-twe-ripple-color="light"
                    onClick={()=>navigate(`/game-types/${urlid}`,{state:{game_types:game_types,id:id}})}
                >
                    PLAY GAME
                </button>
                
                }
            </div>

        </>
    )
}

export default PlayCards